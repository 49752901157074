// Generated by Framer (2923eb5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/Y5TeprnIB";

const enabledGestures = {UqtWsY2x3: {hover: true}};

const serializationHash = "framer-1Jdrg"

const variantClassNames = {UqtWsY2x3: "framer-v-bdmevh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 48, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, link, linkText, newTab, width, ...props}) => { return {...props, LN4vuxuic: link ?? props.LN4vuxuic, lWZlQ0bq8: newTab ?? props.lWZlQ0bq8, oKY8c5dKd: linkText ?? props.oKY8c5dKd ?? "Home"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;linkText?: string;link?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, oKY8c5dKd, LN4vuxuic, lWZlQ0bq8, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "UqtWsY2x3", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={LN4vuxuic} openInNewTab={lWZlQ0bq8} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-bdmevh", className, classNames)} framer-3ek45i`} data-framer-name={"Links"} layoutDependency={layoutDependency} layoutId={"UqtWsY2x3"} ref={ref ?? ref1} style={{opacity: 0.7, ...style}} variants={{"UqtWsY2x3-hover": {opacity: 1}}} {...addPropertyOverrides({"UqtWsY2x3-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1rid3lq"} data-styles-preset={"Y5TeprnIB"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-aed0a6d4-81a3-4db6-8060-7206b0ec1baf, rgb(31, 31, 31)))"}}>Home</motion.p></React.Fragment>} className={"framer-1kms9we"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"igPyyjLwG"} style={{"--extracted-r6o4lv": "var(--token-aed0a6d4-81a3-4db6-8060-7206b0ec1baf, rgb(31, 31, 31))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={oKY8c5dKd} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1Jdrg.framer-3ek45i, .framer-1Jdrg .framer-3ek45i { display: block; }", ".framer-1Jdrg.framer-bdmevh { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-1Jdrg .framer-1kms9we { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1Jdrg.framer-bdmevh { gap: 0px; } .framer-1Jdrg.framer-bdmevh > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-1Jdrg.framer-bdmevh > :first-child { margin-left: 0px; } .framer-1Jdrg.framer-bdmevh > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 53
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"AatwsKw54":{"layout":["auto","auto"]}}}
 * @framerVariables {"oKY8c5dKd":"linkText","LN4vuxuic":"link","lWZlQ0bq8":"newTab"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framers2Hrh1VYi: React.ComponentType<Props> = withCSS(Component, css, "framer-1Jdrg") as typeof Component;
export default Framers2Hrh1VYi;

Framers2Hrh1VYi.displayName = "Footer Links";

Framers2Hrh1VYi.defaultProps = {height: 31, width: 53};

addPropertyControls(Framers2Hrh1VYi, {oKY8c5dKd: {defaultValue: "Home", displayTextArea: false, title: "Link Text", type: ControlType.String}, LN4vuxuic: {title: "Link", type: ControlType.Link}, lWZlQ0bq8: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(Framers2Hrh1VYi, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})